body {
    background: #f0f0f1;
    min-width: 0;
    color: #3c434a;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 13px;
    line-height: 1.4;
  }
  body.login {
    /* background-color: #ddd5c3; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    display: table;
    min-height: 100vh;
    width: 100%;
    padding: 0;
  }
  body.login {
    display: flex;
    flex-direction: column;
  }
  
  body.login {
    background-image: url("../images/black-credit-card.jpeg");
    background-color: #000000;
    background-position: center;
    background-size: contain;
    position: relative;
  }
  
  .login h1 a {
    background-size: 84px;
    background-position: center top;
    background-repeat: no-repeat;
    color: #3c434a;
    height: 84px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
    margin: 0 auto 25px;
    padding: 0;
    text-decoration: none;
    width: 84px;
    text-indent: -9999px;
    outline: 0;
    overflow: hidden;
    display: block;
  }
  
  .login h1 a {
    background-image: url(https://corpcreditbuilder.com/wp-content/uploads/2022/01/Easier-accounting-logo-white.png) !important;
    width: 236px !important;
    height: 78px !important;
    background-size: contain !important;
  }
  
  .login #backtoblog a:hover,
  .login #nav a:hover,
  .login h1 a:hover {
    color: #135e96;
  }
  
  .login form {
    margin-top: 20px;
    margin-left: 0;
    padding: 26px 24px 34px;
    font-weight: 400;
    overflow: hidden;
  }
  
  .login label {
    color: #ffffff;
  }
  .login label {
    font-size: 14px;
    line-height: 1.5;
    display: inline-block;
    margin-bottom: 3px;
  }
  
  .login #nav {
    color: #d6edff;
    font-size: 13px;
  }
  
  #login {
      min-width: 374px;
  }
  
  #login {
    margin-top: 2%;
    background: #fff;
    position: relative;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 15px rgb(0 0 0 / 80%);
    box-shadow: 0 0 15px rgb(0 0 0 / 80%);
    max-width: 350px;
    padding: 26px 26px 6px 26px;
    width: 100%;
  }
  
  #login {
    background-color: #00101e;
    border-radius: 23px;
    box-shadow: 0 0 23px rgb(0 0 0 / 77%);
  }
  