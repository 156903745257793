@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-black tracking-wide;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold;
  }

  h1 {
    @apply text-3xl sm:text-4xl md:text-5xl;
  }

  h2 {
    @apply text-2xl sm:text-3xl;
  }

  li {
    @apply cursor-pointer;
  }
}
